@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.container {
  width: 100%;
}

.shippingWrapper {
  border: 1px solid $color-checkout-border;
  width: 100%;

  form {
    padding: $space-md $space-xs;
  }

  @include md-max {
    margin-bottom: $space-sm;
  }
}

.header {
  background-color: $color-black;
  display: flex;
  align-items: center;
  padding: $space-sm $space-xs;

  h1 {
    font-size: $font-size-sm;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    font-family: $body-font;
    letter-spacing: $letter-spacing-headline;
    color: $color-white;
    margin: 0;
  }
}

.checkoutLogin {
  text-align: center;
  padding: $space-md $space-xs;

  a {
    color: $color-black;
    font-weight: $font-weight-bold;
  }
}
