@use "@/styles/variables" as *;
@use "@/styles/helpers/overlayStyles" as *;
@use "@/styles/component/modal.scss" as *;
@use "@/styles/utils" as *;

.container {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: $space-xs 0;
  background-color: $color-secondary;
  transition: transform 0.5s;
  text-transform: uppercase;

  p {
    margin: 0;
  }

  .close,
  .spacer {
    margin: 0 $space-xxs;
    min-width: $space-sm;
  }

  .close {
    text-decoration: none;
    font-size: $font-size-lg;
    line-height: 1;
    cursor: pointer;
  }

  .img {
    position: absolute;
    z-index: $disabled-layer;

    img {
      width: 100%;
    }
  }

  &.hidden {
    position: absolute;
    pointer-events: none;
    display: none;
  }

  &.header {
    z-index: $disabled-layer;

    &.hidden {
      transform: translateY(-100%);
    }
  }

  &.footer {
    z-index: 8;
    position: sticky;
    bottom: 0;

    &.hidden {
      display: none;
      transform: translateY(100%);
    }
  }
}

.footer {
  div {
    a,
    p {
      font-family: $heading-font;
      letter-spacing: $letter-spacing-headline;
    }
  }
}
