////
/// @deprecated To be replaced with styling for a re-usable
/// modal component.
////

@use "@/styles/variables" as *;

.modalContainer {
  .modalHeader {
    background-color: $bg-grey;
    position: relative;
    padding: $space-md;
    text-transform: uppercase;

    h4 {
      margin: 0;
    }
  }

  .modalMain {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: $space-md;
  }

  .modalClose {
    position: absolute;
    top: 50%;
    right: $space-sm;
    transform: translateY(-50%);
  }
}
