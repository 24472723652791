@use '@/styles/variables/colors' as *;

@keyframes show-curtain-keyframes {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.curtain {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background: rgba($color-black, 0.184);
  opacity: 0;
  animation: show-curtain-keyframes 300ms ease forwards;
}
