@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.input {
  line-height: 20px;
  margin-bottom: 10px;
  padding: 0 10px;
}

.submitButton {
  margin-top: 8px;
}
