@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.wrapper {
  width: 100%;
  border: 1px solid $color-border-light;

  @include md-min {
    max-width: 340px;
  }
}

.title {
  background-color: $color-white-gray;
  font-size: 15px;
  color: $color-black;
  font-weight: $font-weight-bold;
  height: 40px;
  line-height: 40px;
  padding-left: 10px;
}

.description {
  margin-bottom: 15px;
}

.body {
  padding: 15px 20px 25px;
}
