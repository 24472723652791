@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.modalContainer {
  @include sm-min {
    width: min(770px, 95vw);
    height: min(90vh, 770px);
    max-height: 550px;
    max-width: 100%;
  }
}

.hide {
  display: none;
}

.modalHeader {
  background-color: $color-gray-light;
  font-weight: $font-weight-bold;
  letter-spacing: $letter-spacing-headline;
  text-transform: uppercase;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.modalBody {
  padding: $space-sm;
}
