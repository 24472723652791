@use "sass:math";
@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.image {
  object-fit: contain;
  object-position: left;
}

.cart {
  // Height x width resets -- too much going on. styles on image should be enough
  height: auto;
  width: auto;
  background-color: $color-white;
  cursor: pointer;
  padding-top: $space-xs;
  text-align: center;
  padding-bottom: 0;

  .content {
    height: fit-content;
    max-height: unset;
    width: 100%;
  }

  > div {
    padding-inline: $space-sm;
  }

  .imageContainer {
    margin-top: 0;
    margin-bottom: $space-md;
    margin-inline: auto;
  }

  .productName {
    margin-bottom: 1em;
    font: 300 15px $heading-font;
    letter-spacing: $letter-spacing-headline;
    text-transform: uppercase;
    line-height: $line-height-relaxed;
  }

  .infoRow {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 0.5em;
    font-size: $font-size-base;
    font-family: $body-font;
    color: $color-black;
    margin-bottom: $space-md;
    text-transform: capitalize;

    .variants {
      font-style: normal;
      white-space: nowrap;
    }
  }

  .buttonContainer {
    text-decoration: underline;
    padding-bottom: calc(#{$space-xxs} * 0.5);

    button {
      background-color: transparent;
      display: block;
      color: $color-black;
      text-transform: capitalize;
      text-decoration: underline;
      padding: 0;
      border: none;
      line-height: 0;
      height: fit-content;
      padding-block: $space-xs;
      letter-spacing: 0;
    }
  }
}

.productTile {
  // Use `position: relative` so absolutely positioned
  // children can do their thing.
  position: relative;
  text-align: center;
  border: 1px solid $color-gray-2;
  background-color: $color-white;

  // Allow overflow on the y axis so .content
  // is still visible when expanded.
  overflow-y: visible;

  // As default, take all available width and 247px height
  width: 100%;
  height: 247px;

  &.withProductSwatchSlider {
    height: 297px;
  }

  cursor: pointer;

  @include md-min {
    // On md screens and up, take 418px height
    height: 418px;

    &.withProductSwatchSlider {
      height: 458px;
    }
  }

  // Declaring the badge height here to use it for both the
  // actual badge and the hover effect.
  $badge-height: 25px;

  @include md-min {
    $badge-height: 27.5px;
  }

  @include lg-min {
    $badge-height: 30px;
  }

  // The content is in a separate container so it
  // can expand without displacing the other tiles.
  .content {
    // Use `position: relative` so z-index can be incremented
    // on hover to show this tile above the rest.
    position: relative;
    z-index: 0;
    background-color: transparent;
    padding-bottom: 10px;

    // Hide overflow to prevent the image borders from
    // overflowing on its rotate transformation on hover.
    overflow: hidden;
    height: fit-content;

    // Limit and transition max-height so height can use
    // dynamic values such as auto or fit-content.
    // https://css-tricks.com/using-css-transitions-auto-dimensions/
    max-height: 295px;

    &.withProductSwatchSlider {
      height: 468px;
    }

    // This one is tricky. `z-index` must have no delay when expanding,
    // but it should have a delay of 250ms when contracting
    // so positioned components like badges from other
    // tiles don't immediately appear over the current one
    // when the mouse leaves. (See &:hover > .content below.)
    transition: max-height 250ms, z-index 250ms;

    @include md-min {
      max-height: 470px;

      &.withProductSwatchSlider {
        max-height: 510px;
      }
    }

    @include lg-min {
      background-color: $color-light;
    }

    // Pad the tile a bit when loading, so that the
    // skeleton loaders don't touch the edges.
    &.loading {
      padding: 10px 25px;
    }
  }

  // Make the loaders rounded and at least 20px thick.
  .skeletonLoader {
    border-radius: 5px;
    min-height: 20px;
  }

  .quickShopButton {
    // Position: top left with a bit of space above.
    // Ends up centered because it takes all of the parent's
    // width anyway.
    width: 100%;
    position: absolute;
    left: 0;
    top: $badge-height + 15px;
    z-index: 1;

    cursor: pointer;

    // This one appears on hover, with a "slide down"
    // transition, so start by being completely transparent
    // and translated a bit upwards.
    transform: translateY(-10px);
    opacity: 0;
    transition: 250ms;

    > button {
      font: $font-size-xs $body-font;
      text-decoration: none;
      font-style: italic;
      text-transform: uppercase;
      letter-spacing: 1px;
    }
  }

  .imageContainer {
    cursor: pointer;
    position: relative;
    width: 100%;
    transform: rotate(0deg);
    transition: transform 250ms;
    height: 170px;
    margin: 0 0 $space-base;

    @media (max-width: $breakpoint-md) {
      margin: 0 0 $space-sm;
    }

    @include md-min {
      height: 296px;
    }

    @include lg-min {
      height: 241px;
      margin: $space-base 0;
    }
  }

  .productName {
    width: 100%;
    margin-bottom: 10px;
    font: 300 15px $heading-font;
    letter-spacing: $letter-spacing-headline;
    text-transform: uppercase;

    .skeletonLoader {
      width: 50%;
      margin: auto;
    }
  }

  .ratings {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 0;

    // For transition
    opacity: 0;
    height: auto;
    max-height: 0;
    transition: 250ms;

    .skeletonLoader {
      width: 35%;
      margin: auto;
    }
  }

  .infoRow {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    font-family: $body-font;
    color: $color-black-gray;
    margin-bottom: $space-sm;

    .previousPrice,
    .currentPrice,
    .variantsDivider {
      margin-right: 5px;
    }

    .previousPrice {
      color: $color-text-muted;
      text-decoration: line-through;
    }

    .currentPrice {
      margin-right: 5px;
      font-weight: $font-weight-bold;
    }

    .variants {
      font-weight: normal;
    }

    .variantsDivider,
    .variants {
      // Don't display on xxs screens to prevent cramming
      display: none;

      @include xs-min {
        display: block;
      }
    }

    .skeletonLoader {
      width: 30%;
      margin: auto;
    }
  }

  .productSwatchRow {
    display: flex;
    justify-content: start;
    align-items: center;
    width: 100%;
    margin-bottom: $space-sm;

    @media (max-width: $breakpoint-md) {
      margin-bottom: $space-xxs;
    }
  }

  .colors {
    // For transition
    opacity: 0;
    height: auto;
    max-height: 0;
    transition: 250ms;
  }

  .promotionText {
    font: bold $font-size-xs $body-font;
    color: $color-dark;
    text-align: center;

    margin-bottom: 10px;

    // For transition
    height: auto;
    max-height: 15px;
    opacity: 1;
    transition: 250ms;
  }

  .badge {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    display: flex;
    justify-content: flex-start;

    transition: 150ms;

    width: 100%;
    height: $badge-height;
  }

  &.recommendation {
    position: relative;
    text-align: center;
    background-color: $color-white;
    overflow-y: visible;
    border: none;
    border-radius: 15px;
    flex: 0 0 50%;
    height: 250px;

    &.slideTile {
      flex: 0 0 75%;

      @include sm-max {
        flex: 0 0 70%;
      }

      @include md-min {
        flex: 0 0 calc(50% - $space-xxs);
        height: 400px;
      }

      @include lg-min {
        flex: 0 0 calc(25% - 10px);
      }
    }

    @include sm-max {
      margin: 0 0 0 $space-xs;

      &:last-child {
        margin: 0 $space-xs;
      }
    }

    @include md-min {
      height: 398px;
    }

    @include lg-min {
      flex: 0 0 25%;
      height: 345px;
    }

    .slideTileImage {
      background-color: $color-gray-light;
    }

    > .content {
      box-shadow: none;
      border-radius: 15px;
      max-height: 400px;

      > .imageContainer {
        background-color: $color-gray-light;
        border: 5px solid $color-white;
        border-radius: 15px;

        transform: rotate(0deg);
        transition: transform 250ms;

        height: 150px;
        margin-bottom: 20px;

        @include md-min {
          height: 296px;
        }

        @include lg-min {
          height: 241px;
        }
      }

      .variants {
        font-style: italic;
        font-weight: normal;
      }
    }
  }

  // When the tile is neither a cart tile nor currently loading...
  &:not(.cart):hover,
  &:not(.loading):hover {
    // On hover:
    & .content {
      // Expand content, add a shadow, and increase z-index
      z-index: 2;
      transition: max-height 250ms, z-index 0s;

      @include lg-min {
        max-height: 400px;
      }

      // Rotate badge slightly and translate it so it doesn't overflow.
      .badge {
        transform: rotate(5deg) translateY(($badge-height / 2) + 2.5px)
          translateX(13px);
      }

      // Show quick shop button (undo translation and make opaque)
      .quickShopButton {
        display: none;
        transform: translateY(0);
        opacity: 1;

        @include md-min {
          display: block;
        }
      }

      // Show rating stars (expand and make opaque)
      .ratings {
        max-height: 10px;
        margin-bottom: 10px;
        opacity: 1;

        @include lg-min {
          max-height: 15px;
        }
      }

      // Show color selector (expand and make opaque)
      .colors {
        max-height: 15px;
        margin-bottom: 6px;
        opacity: 1;
      }

      // Hide text on hover
      .variants,
      .variantsDivider,
      .promotionText {
        max-height: 0;
        max-width: 0;
        opacity: 0;
      }
    }
  }
}
