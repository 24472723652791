@use "@/styles/variables" as *;

.placeholder {
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  $max-spinner-size: $font-size-xxxxl;

  color: $color-primary;
  width: 75%;
  height: 75%;

  max-width: $max-spinner-size;
  max-height: $max-spinner-size;
}
