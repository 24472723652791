////
/// Defines variables relating to window / device "breakpoints".
/// @group variables/breakpoints
////

// Breakpoints
$breakpoint-xxs: 0;
$breakpoint-xs: 375px;
$breakpoint-sm: 640px;
$breakpoint-md: 768px;
$breakpoint-lg: 1024px;
$breakpoint-xl: 1440px;
