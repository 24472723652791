@use "@/styles/variables" as *;
@use "@/styles/helpers/overlayStyles" as *;
@use "@/styles/component/modal.scss" as *;
@use "@/styles/utils" as *;

.text {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-wrap: wrap;

  a {
    margin-left: $space-xxs;
  }
}

.modal {
  width: 100%;
  max-width: 750px;
  height: auto;
}

.modalHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  position: relative;

  p {
    margin: 0;
  }
}

.modalBody {
  margin: 20px 60px 0;
}
