@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

$space-big: 20px !default;
$space-medium: 15px !default;
$field-top-margin: 7px !default;

.input {
  margin-bottom: $field-top-margin;
}

.passwordDetails {
  color: $color-danger-darker;
  font-size: $font-size-xs;
  font-weight: $font-weight-bold;
  margin-top: -$space-medium;

  ul {
    padding: 0;
    list-style-position: inside;

    li {
      line-height: 25px;

      &:first-child {
        list-style: none;
      }
    }
  }
}

.phoneExample {
  margin-top: 0;
  font-size: $font-size-xs;
}

.emailList {
  margin-bottom: $space-medium;

  label {
    font-size: $font-size-xs;
  }

  a {
    font-size: $font-size-xs;
    font-weight: $font-weight-bold;
  }
}

.spinner {
  width: 16px;
  height: 16px;
}
