@use "@/styles/variables" as *;

.container {
  position: relative;
  margin: 0 $space-xs;
  padding: $space-xs 0;
}

.modalClose {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  font-size: $font-size-lg;
  font-weight: $font-weight-base;
  border: 0;
  background: transparent;
  cursor: pointer;
}
