@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

@forward "./styles.base.module.scss";

.container {
  font-size: $font-size-xs;
  letter-spacing: $letter-spacing-small-text;

  a {
    font-size: $font-size-xs;
    letter-spacing: $letter-spacing-small-text;
  }

  .close,
  .spacer {
    @include md-min {
      margin: 0 $space-lg;
    }
  }
}
